import { Component } from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";
declare var ga: Function;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
//public static API_URL:string='https://dev.acadware.net/v6.45/';
//public static API_URL:string='https://dev.acadware.net/v6.44/';
//public static API_URL:string='https://dev.acadware.net/v6.43/';
//public static API_URL:string='https://stg.acadware.net/v6.45/';
 public static API_URL:string='https://acadware.net/v6.45/';

// google Analytics s
constructor(public router: Router) 
  {    

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {       
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
        
    //   }
    // });
    
  }

  
}
