import { Injectable } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiUrlConstant } from '../../../app/apiUrl.constant';
import { appInitializerFactory } from '@angular/platform-browser/src/browser/server-transition';

@Injectable()
export class ChartService {
  academy_head_count: any;
  center_head_count: any;
  academy_center_utlization: any;
  center_wise_utlization: any;
  center_wise_attendance_details: any;
  academy_attendance_details: any;
  allcenter_student_headcount_details: any;
  center_headcount_table: any;
  academy_age_count_data: any;
  all_center_age_count_data: any;

  payment_data: any;
  httpOptions;
  api_key = 'AIzaSyDUDJV1FTBHW4ANl8hudvnr-cQPUFF_YYI';
  date_format: string;
  temp_date: string;
  graph_data: any;


  constructor(private http_client: HttpClient) {

  }

  getAcademyHeadCount(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_academyheadcount_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyStudentHeadCount;
    return this.http_client.post(local_academyheadcount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.academy_head_count = data['student_headcount'];
          if (this.academy_head_count.length != 0) {
            return this.academy_head_count;
          }
          else {
            return '';
          }
        }),
      )
  }

  getChildAcademyStudentHeadCountAverage(organization_id, login_user_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('academy_parent_id', organization_id)
      .set('login_user_id', login_user_id);
    const local_academyheadcount_url = AppComponent.API_URL + ApiUrlConstant.GetChildAcademyStudentHeadCountAverage;
    return this.http_client.post(local_academyheadcount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.academy_head_count = data['child_academy_student_headcount_average'];
          if (this.academy_head_count.length != 0) {
            return this.academy_head_count;
          }
          else {
            return '';
          }
        }),
      )
  }

  getCenterWiseHeadCount(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_centerheadcount_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterStudentHeadCount;
    return this.http_client.post(local_centerheadcount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.center_head_count = data['student_headcount']['center_data'];
          if (this.center_head_count.length != 0) {
            return this.center_head_count;
          }
          else {
            return '';
          }
        }),
      )
  }

  getAcademyCenterUtlization(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_academyutlization_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyCenterUtilization;
    return this.http_client.post(local_academyutlization_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.academy_center_utlization = data['center_utilization_details'];
          if (this.academy_center_utlization.length != 0) {
            return this.academy_center_utlization;
          }
          else {
            return '';
          }
        }),
      )
  }

  getAcademyAllCenterUtlization(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_academycenterutlization_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterUtilization;
    return this.http_client.post(local_academycenterutlization_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.center_wise_utlization = data['center_utilization_details']['center_data'];
          if (this.center_wise_utlization.length != 0) {
            return this.center_wise_utlization;
          }
          else {
            return '';
          }
        }),
      )
  }

  getPaymentDetails(organization_id, period, from_month, to_month, from_year, to_year) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };

    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('period', period);
    if (period == 'custom') {
      params = params.append('from_month', from_month);
      params = params.append('to_month', to_month);
      params = params.append('from_year', from_year);
      params = params.append('to_year', to_year);
    }
    const local_academypayment_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterPaymentDetails;
    return this.http_client.post(local_academypayment_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.payment_data = data['academy_payment_details'];
          if (this.payment_data.length != 0) {
            return this.payment_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getAcademyAttedance(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_GetAcademyAttedance_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAttedance;
    return this.http_client.post(local_GetAcademyAttedance_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.academy_attendance_details = data['academy_attendance_details'];
          if (this.academy_attendance_details.length != 0) {
            return this.academy_attendance_details;
          }
          else {
            return '';
          }
        }),
      )
  }

  getAcademyAllCenterAttendance(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_getAcademyAllCenterAttendance_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterAttendance;
    return this.http_client.post(local_getAcademyAllCenterAttendance_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.center_wise_attendance_details = data['center_wise_attendance_details'];
          if (this.center_wise_attendance_details.length != 0) {
            return this.center_wise_attendance_details;
          }
          else {
            return '';
          }
        }),
      )

  }
  getAcademyAllCenterStudentHeadCountDonut(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_getAcademyAllCenterheadcount_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterStudentHeadCountDonut;
    return this.http_client.post(local_getAcademyAllCenterheadcount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.allcenter_student_headcount_details = data['student_headcount'];
          if (this.allcenter_student_headcount_details.length != 0) {
            return this.allcenter_student_headcount_details;
          }
          else {
            return '';
          }
        }),
      )
  }

  getCenterWiseHeadCountTable(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_getAcademyAllCenterheadcount_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterStudentHeadCountTable;
    return this.http_client.post(local_getAcademyAllCenterheadcount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['student_headcount'] != false) {
            this.center_headcount_table = data['student_headcount']['center_data'];
            if (this.center_headcount_table.length != 0) {
              return this.center_headcount_table;
            }
            else {
              return '';
            }
          } else {
            return '';
          }

        }),
      )
  }


  getAcademyAgeCount(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_getAcademyAgeCount_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAgeCount;
    return this.http_client.post(local_getAcademyAgeCount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.academy_age_count_data = data['academy_age_count'];
          if (this.academy_age_count_data.length != 0) {
            return this.academy_age_count_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getChildAcademyAgeCount(organization_id, login_user_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('academy_parent_id', organization_id)
      .set('login_user_id', login_user_id);
    const local_getAcademyAgeCount_url = AppComponent.API_URL + ApiUrlConstant.GetChildAcademyAgeCount;
    return this.http_client.post(local_getAcademyAgeCount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.academy_age_count_data = data['child_academy_age_count'];
          if (this.academy_age_count_data.length != 0) {
            return this.academy_age_count_data;
          }
          else {
            return '';
          }
        }),
      )
  }

  GetAcademyAllCenterStudentAgeCount(organization_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('organization_id', organization_id);
    const local_AllcenterAgeCount_url = AppComponent.API_URL + ApiUrlConstant.GetAcademyAllCenterStudentAgeCount;
    return this.http_client.post(local_AllcenterAgeCount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          this.all_center_age_count_data = data['all_center_age_count'];
          if (this.all_center_age_count_data.length != 0) {
            return this.all_center_age_count_data;
          }
          else {
            return '';
          }
        }),
      )
  }
  getAcademyEntitlement(academy_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set("academy_id", academy_id);
    const url = AppComponent.API_URL + ApiUrlConstant.GetAcadmeyDashboardEntitlement;
    return this.http_client.post(url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }
  getChildOrganizations(login_customer_id, academy_parent_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    const params = new HttpParams().set('login_user_id', login_customer_id)
      .set('academy_parent_id', academy_parent_id);
    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetChildOrganizations;
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }
  getCordinateByAddress(location) {
    return this.http_client.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + location + '&key=' + this.api_key)
      .pipe(tap((data) =>
        (console.log()))

      );
  }
  getChildAcademyListForDashboard(login_user_id, academy_parent_id) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };

    let params = new HttpParams();
    params = params.append('login_user_id', login_user_id);
    params = params.append('academy_parent_id', academy_parent_id);


    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetChildAcademyDetailsForDashboard;
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          return data
        }),
      )
  }

  getChildAcademyDetailsForDashboard(login_user_id, academy_parent_id, selected_filter, facet_navigation) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };


    let params = new HttpParams();
    params = params.append('academy_parent_id', academy_parent_id);
    params = params.append('login_user_id', login_user_id);

    if (facet_navigation != undefined) {
      var index = 0;
      facet_navigation.forEach((element, i) => {
        var count = 0;

        selected_filter.forEach((data, j) => {

          element['category_values'].forEach((value, k) => {
            if (data.category_value == value.category_value) {
              if (count == 0) {
                params = params.append('filters[categories][' + index + '][category_id]', element.category_id);
              }
              params = params.append('filters[categories][' + index + '][category_value_id][' + count + ']', value.category_value_id);
              count++;
            }
          });

        });
        if (count != 0) {
          index++;
        }
      });
    }


    // const local_playerlist_url = AppComponent.API_URL + 'dashboard/GetChildAcademyDetailsForDashboard';  
    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetChildAcademyUtilizationDetails;
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status'] == 200) {
            return data
          }
          else {
            return '';
          }

        }),
      )
  }

  getChildAcademyStudentHeadCount(academy_parent_id, login_user_id,) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    let params = new HttpParams();
    params = params.append('academy_parent_id', academy_parent_id);
    params = params.append('login_user_id', login_user_id);


    const local_centerheadcount_url = AppComponent.API_URL + ApiUrlConstant.GetChildAcademyStudentHeadCount;
    return this.http_client.post(local_centerheadcount_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          var head_count = data['child_academy_student_headcount'];
          if (head_count.length != 0) {
            return head_count;
          }
          else {
            return '';
          }
        }),
      )
  }

  getAssesmentDetails(organization_id) {
    const params = new HttpParams().set('organization_id', organization_id);
    const local_assesment_url = AppComponent.API_URL + ApiUrlConstant.GetCascadedAssessmentList;
    return this.http_client.post(local_assesment_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['assessment_list'].length != 0) {
            return data['assessment_list'];
          }
          else {
            return '';
          }
        }),
      )
  }
  getAssessmentConsolidatedRankingDetails(performance_set_assessment_id, activity_id, genderFilter,
    organization_ids, country_id, state_id, city_id) {
    let params = new HttpParams();
    params = params.append('performance_set_assessment_id', performance_set_assessment_id);
    params = params.append('activity_id', activity_id);
    if (genderFilter != undefined) {
      params = params.append('filters[gender]', genderFilter);
    }
    if (organization_ids != undefined) {
      params = params.append('filters[organization_id]', organization_ids);
      // organization_ids.forEach((element,i) => {
      //   params =params.append('filters[organization_id]', element);
      // });
    }
    if (country_id != undefined) {
      params = params.append('filters[location][country_id]', country_id);
    }
    if (state_id != undefined) {
      params = params.append('filters[location][state_id]', state_id);
    }
    if (city_id != undefined) {
      params = params.append('filters[location][city_id]', city_id);
    }

    const local_assesment_url = AppComponent.API_URL + ApiUrlConstant.GetAssessmentConsolidatedRankingDetails;
    return this.http_client.post(local_assesment_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status']) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  //Countries list
  AddOrganizationFormData() {
    const contries_list_url = AppComponent.API_URL + ApiUrlConstant.AddOrganizationFormData;
    return this.http_client.post(contries_list_url, [], this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['add_organization_form_data'].length != 0) {
            return data['add_organization_form_data'];
          }
          else {
            return '';
          }
        }),
      )
  }

  //State List
  GetStates(country_id) {
    const params = new HttpParams().set('country_id', country_id);
    var body = `country_id=${country_id}`;
    const state_list_url = AppComponent.API_URL + ApiUrlConstant.GetStates;
    return this.http_client.post(state_list_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['states_list'].length != 0) {
            return data['states_list'];
          }
          else {
            return '';
          }
        }),
      )
  }

  getCities(country_id, state_id) {
    const params = new HttpParams().set('country_id', country_id).set('state_id', state_id);

    const local_center_url = AppComponent.API_URL + ApiUrlConstant.GetCities;
    return this.http_client.post(local_center_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['cities_list'].length != 0) {
            return data['cities_list'];
          }
          else {
            return '';
          }
        }),
      )
  }

  updatePlayerPerformanceConsoliatedRanking(activity_id, performance_set_assessment_id,
    login_user_id, academy_parent_id) {
    let params = new HttpParams();
    params = params.append('activity_id', activity_id);
    params = params.append('performance_set_assessment_id', performance_set_assessment_id);
    params = params.append('login_user_id', login_user_id);
    params = params.append('academy_parent_id', academy_parent_id);


    const local_assesment_url = AppComponent.API_URL + ApiUrlConstant.UpdatePlayerPerformanceConsoliatedRanking;
    return this.http_client.post(local_assesment_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status']) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getPlayerConsolidatedRankingAssesmentReport(academy_id, activity_id,
    performance_set_assessment_id) {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('activity_id', activity_id);
    params = params.append('performance_set_assessment_id', performance_set_assessment_id);

    const local_assesment_url = AppComponent.API_URL + ApiUrlConstant.GetPlayerConsolidatedRankingAssesmentReport;
    return this.http_client.post(local_assesment_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {
          if (data['status']) {
            return data;
          }
          else {
            return '';
          }
        }),
      )
  }

  getChildAcademyUtilizationFacetNevigation(login_user_id, academy_parent_id, selected_filter, facet_navigation) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };

    let params = new HttpParams();
    params = params.append('login_user_id', login_user_id);
    params = params.append('academy_parent_id', academy_parent_id);

    if (facet_navigation != undefined) {
      var index = 0;
      facet_navigation.forEach((element, i) => {
        var count = 0;

        selected_filter.forEach((data, j) => {

          element['category_values'].forEach((value, k) => {

            if (data.category_value == value.category_value) {
              if (count == 0) {
                params = params.append('filters[categories][' + index + '][category_id]', element.category_id);
              }
              params = params.append('filters[categories][' + index + '][category_value_id][' + count + ']', value.category_value_id);
              count++;
            }
          });

        });
        if (count != 0) {
          index++;
        }
      });
    }

    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetChildAcademyUtilizationFacetNevigation;
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status'] == 200) {
            return data
          }
          else {
            return '';
          }

        }),
      )
  }

  getCenterUtiizationGraphDetails(organization_id, period, from_date, to_date,date_format) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };


    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('filters[period]', period);
    if (period == 'custom') {
      if (from_date != undefined) {
        params = params.append('filters[from_date]', this.dateFormater(from_date,date_format));
      }

      if (to_date != undefined) {
        params = params.append('filters[to_date]', this.dateFormater(to_date,date_format));
      }

    }


    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.GetCenterUtiizationGraphDetails;
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status'] == 200) {
            return data
          }
          else {
            return '';
          }

        }),
      )
  }
  staffLiveAttendace(organization_id, parent_academy_flag) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };


    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    parent_academy_flag == 'Y' ? params = params.append('from_academy', 'parent') : '';

    const local_playerlist_url = AppComponent.API_URL + ApiUrlConstant.TodaysLiveAttendance;
    return this.http_client.post(local_playerlist_url, params, this.httpOptions)
      .pipe(
        map((data: any) => {

          if (data['status'] == 200) {
            return data
          }
          else {
            return '';
          }

        }),
      )
  }

  getMonthlyReportOfPlayerForGraph(academy_id,from_date,to_date,date_format) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': '' + localStorage.getItem("access_token")
      })
    };
    let params = new HttpParams();
    params = params.append('academy_id',academy_id);
    // academy_id.forEach((element,i) => {
    //   params =params.append('academy_id['+i+']', element);  
    // });

    if (from_date != undefined && from_date != null) {
      params = params.append('filter[from_date]', this.dateFormater(from_date, date_format));

    }
    if (to_date != undefined && to_date != null) {
      params = params.append('filter[to_date]',this.dateFormater(to_date,date_format));
    }

    const url = AppComponent.API_URL + 'report/GetChildLevelMonthlyStudentCount';
    return this.http_client.post(url, params, this.httpOptions).pipe(
      map((data: any) => {
        this.graph_data=data['data'];
        if (data['status'] == 200) {
          return this.graph_data;
        }
        else {
          return '';
        }
      })
    )

  }


  dateFormater(date,date_format) {
    this.date_format = sessionStorage.getItem('date_format');
    var day: any;
    var month: any;
    day = date.getDate();
    month = (date.getMonth() + 1);
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (this.date_format == "Y-m-d") {
      return this.temp_date = date.getFullYear() + "-" + month + "-" + day;
    }
    if (this.date_format == "d-m-Y") {
      return this.temp_date = day + "-" + month + "-" + date.getFullYear();
    }
    if (this.date_format == "Y/m/d") {
      return this.temp_date = date.getFullYear() + "/" + month + "/" + day;
    }

    if (this.date_format == "d/m/Y") {
      return this.temp_date = day + "/" + month + "/" + date.getFullYear();
    }

    if (this.date_format == "m/d/Y") {
      return this.temp_date = day + "/" + month + "/" + date.getFullYear();
    }


  }

}