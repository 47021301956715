import { Injectable } from '@angular/core';
import {AppComponent} from '../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class FeePlansService {

  academy_center_id:any;
  New_feePlans_details:any='';
  allowDelete:any='';
  delete_fees_plan_details:any='';
  update_fees_plan_details:any=''; 
  center_data:any='';
  add_flag:any;
  httpOptions;  

  constructor(private http_client: HttpClient)
  {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
 
   getFeePlansList(organization_id,academy_center_id?)
  {
    let params=new HttpParams();
    //const  params = new  HttpParams().set('organization_id', organization_id);
    params=params.append('organization_id',organization_id);
    if(academy_center_id!=undefined){
      academy_center_id.forEach((element, i) => {
        params = params.append('filters[academy_center_id][' + i + ']', element);
      });
    }
    const local_feeplans_url=AppComponent.API_URL+'feesetup/GetFeeSetup';
    return this.http_client.post(local_feeplans_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            if(data['status']==200 && data['message']=='Fee plan details.')
            {         
              return data['data']['organization_fee_plans'];
            }
            else
            {
              return '';
            }
          }),
    )
  }
  getOrganizationFeeSetup(organization_id,filter_value, filter_type,child_academies)
  {
    let params = new HttpParams();   
    params =params.append('parent_organization_id', organization_id);
    let flag=false;
    filter_type.forEach((element,i) => {
      if (element == "Academy") 
      {
        for (var j = 0; j < filter_value[i]['Academy'].length; j++) 
        {
          params =params.append('filters[organization_id]'+'['+j+']', filter_value[i]['Academy'][j]);
        }
        flag=true;
      }
    });
    if(!flag){
      child_academies.forEach((element,i) => {
        params =params.append('filters[organization_id]'+'['+i+']', element['academy_child_id']);
      });
    }
    const local_feeplans_url=AppComponent.API_URL+'feesetup/GetOrganizationFeeSetup';
    return this.http_client.post(local_feeplans_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{           
            if(data['status']==200)
            {         
              return data;
            }
            else
              {
                return '';
              }
          }),
    )
  }
  //Add fee plans
  SetFeeSetup(organization_id,form_data,chkProrata,installments_repeat_annually,
    installments_amount,academy_fees_id,edit_flag,installments_amount_percentage,installments_after_month)
  {        
    let params = new HttpParams();   
    params =params.append('organization_id', organization_id);
    params =params.append('fee_setup_name', form_data.fee_setup_name);
    params =params.append('fee_setup_payment_type', form_data.fee_setup_payment_type);
    params =params.append('fee_setup_status', '1');
    if(edit_flag)
    {
      params =params.append('academy_fees_id[0]', academy_fees_id);  
    }
    for(var i=0;i<form_data.academy_center_id.length;i++)
    {
      params =params.append('academy_center_id['+i+']', form_data.academy_center_id[i]);
    }
    if(form_data.fee_setup_payment_type=="Recurring")
    {
      if(form_data.monthlyAmount!=null)
      {
        params =params.append('monthlyAmount', form_data.monthlyAmount);
      }
      else
      {
        params =params.append('monthlyAmount', "0");
      }
      if(form_data.bimonthlyAmount!=null)
      {
        params =params.append('biMonthlyAmount', form_data.bimonthlyAmount);
      }
      else
      {
        params =params.append('bimonthlyAmount', "0");
      }
      if(form_data.quarterlyAmount!=null)
      {
        params =params.append('quarterlyAmount', form_data.quarterlyAmount);
      }
      else
      {
        params =params.append('quarterlyAmount', "0");
      }
      if(form_data.fourMonthAmount!=null)
      {
        params =params.append('fourMonthlyAmount', form_data.fourMonthAmount);
      }
      else
      {
        params =params.append('fourMonthlyAmount', "0");
      }
      if(form_data.halfYearlyAmount!=null)
      {
        params =params.append('halfYearlyAmount', form_data.halfYearlyAmount);
      }
      else
      {
        params =params.append('halfYearlyAmount', "0");
      }
      if(form_data.yearlyAmount!=null)
      {
        params =params.append('yearlyAmount', form_data.yearlyAmount);
      }
      else
      {
        params =params.append('yearlyAmount', "0");
      }

      params =params.append('chkProrata', chkProrata);

      if(form_data.monthylate_fees_flag==true || form_data.bimonthlylate_fees_flag ==true || form_data.quarterlylate_fees_flag ==true 
        || form_data.fourMonthlate_fees_flag ==true || form_data.halfyearlylate_fees_flag ==true || form_data.yearlylate_fees_flag ==true)
      {
        params =params.append('late_fees_flag', "1");
        params =params.append('monthly_late_fee_percentage',form_data.monthly_late_fee_percentage!=null ? form_data.monthly_late_fee_percentage:'');
        params =params.append('monthly_grace_days', form_data.monthly_grace_days!=null ? form_data.monthly_grace_days:'');
        params =params.append('bimonthly_late_fee_percentage', form_data.bimonthly_late_fee_percentage!=null ? form_data.bimonthly_late_fee_percentage:'');
        params =params.append('bimonthly_grace_days', form_data.bimonthly_grace_days!=null ? form_data.bimonthly_grace_days:'');
        params =params.append('quarterly_late_fee_percentage', form_data.quarterly_late_fee_percentage!=null ? form_data.quarterly_late_fee_percentage:'');
        params =params.append('quarterly_grace_days', form_data.quarterly_grace_days!=null ? form_data.quarterly_grace_days:'');
        params =params.append('fourmonthly_late_fee_percentage', form_data.fourmonthly_late_fee_percentage!=null ? form_data.fourmonthly_late_fee_percentage:'');
        params =params.append('fourmonthly_grace_days', form_data.fourmonthly_grace_days!=null ? form_data.fourmonthly_grace_days:'');
        params =params.append('halfyearly_late_fee_percentage', form_data.halfyearly_late_fee_percentage!=null ? form_data.halfyearly_late_fee_percentage:'');
        params =params.append('halfyearly_grace_days', form_data.halfyearly_grace_days!=null ? form_data.halfyearly_grace_days:'');
        params =params.append('yearly_late_fee_percentage', form_data.yearly_late_fee_percentage!=null ? form_data.halfyearly_grace_days:'');
        params =params.append('yearly_grace_days', form_data.yearly_grace_days!=null ? form_data.yearly_grace_days:'');
      }
    } 
    if(form_data.fee_setup_payment_type=="Onetime")
    {
      if(form_data.oneTimePaymentAmount!=null)
      {
        params =params.append('oneTimePaymentAmount', form_data.oneTimePaymentAmount);
      }
      else
      {
        params =params.append('oneTimePaymentAmount', "0");
      }  
      if(form_data.oneTimelate_fees_flag==true)
      {
        params =params.append('late_fees_flag', '1' );
        params =params.append('onetime_late_fee_percentage', form_data.onetime_late_fee_percentage);
        params =params.append('onetime_grace_days', form_data.onetime_grace_days);
      } 
    }     
    if(form_data.fee_setup_payment_type=="Registration")
      {
        if(form_data.registrationAmount!=null)
        {
          params =params.append('registrationAmount', form_data.registrationAmount);
        }
        else
        {
          params =params.append('registrationAmount', "0");
        }  
      }     
    
    if(form_data.fee_setup_payment_type=="Installments")
    {
      params =params.append('total_installment_amount', form_data.total_installment_amount);
      params =params.append('total_installments', form_data.total_installments );
      params =params.append('installments_repeat_annually', installments_repeat_annually );
      installments_amount.forEach((element,i) => {
      params =params.append('installments_amount['+i+']', element);
      
     
        if(form_data['intallmentlate_fees_flag'+i]==true)
        {
          params =params.append('late_fees_flag', '1' );
          params =params.append('installments_late_fee_percentage['+i+']', form_data['installments_late_fee_percentage'+i]);
          params =params.append('installments_grace_days['+i+']', form_data['installments_grace_days'+i]);
        }
      });
      installments_amount_percentage.forEach((element,i) => {
        params =params.append('installments_amount_percentage['+i+']', element);
      });
      installments_after_month.forEach((element,i) => {
        params =params.append('installments_after_month['+i+']', element);
      });
    }
    if(form_data.fee_setup_payment_type=="Subscription")
    {

      if(form_data.billing=='pre_paid'){
        params =params.append('billing', form_data.billing);

        if(form_data.renewal_criteria=='1' || form_data.renewal_criteria=='3'){
          params =params.append('no_of_sessions', form_data.no_of_sessions);
          if(form_data.renewal_criteria=='1'){
            params =params.append('monthlyAmount', form_data.session_monthlyAmount);
          }
          params =params.append('link_session_count_to_attendance_flag', form_data.link_session_count_to_attendance_flag==true?'1':'0');
        }
        if(form_data.renewal_criteria=='2' || form_data.renewal_criteria=='3')
        {
          if(form_data.subscription_monthlyAmount!=null)
          {
            params =params.append('monthlyAmount', form_data.subscription_monthlyAmount);
          }
          else
          {
            params =params.append('monthlyAmount', "0");
          }
          if(form_data.subscription_bimonthlyAmount!=null)
          {
            params =params.append('biMonthlyAmount', form_data.subscription_bimonthlyAmount);
          }
          else
          {
            params =params.append('bimonthlyAmount', "0");
          }
          if(form_data.subscription_quarterlyAmount!=null)
          {
            params =params.append('quarterlyAmount', form_data.subscription_quarterlyAmount);
          }
          else
          {
            params =params.append('quarterlyAmount', "0");
          }
          if(form_data.subscription_fourmonthlyAmount!=null)
          {
            params =params.append('fourMonthlyAmount', form_data.subscription_fourmonthlyAmount);
          }
          else
          {
            params =params.append('fourMonthlyAmount', "0");
          }
          if(form_data.subscription_halfYearlyAmount!=null)
          {
            params =params.append('halfYearlyAmount', form_data.subscription_halfYearlyAmount);
          }
          else
          {
            params =params.append('halfYearlyAmount', "0");
          }
          if(form_data.subscription_yearlyAmount!=null)
          {
            params =params.append('yearlyAmount', form_data.subscription_yearlyAmount);
          }
          else
          {
            params =params.append('yearlyAmount', "0");
          }
          if(form_data.subscription_monthylate_fees_flag==true || form_data.subscription_bimonthlylate_fees_flag ==true || 
            form_data.subscription_quarterlylate_fees_flag ==true || form_data.subscription_fourmonthlylate_fees_flag ==true ||
            form_data.subscription_halfyearlylate_fees_flag ==true || form_data.subscription_yearlylate_fees_flag ==true)
          {
            params =params.append('late_fees_flag', "1");
            params =params.append('monthly_late_fee_percentage',form_data.subscription_monthly_late_fee_percentage!=null ? form_data.subscription_monthly_late_fee_percentage:'');
            params =params.append('monthly_grace_days', form_data.subscription_monthly_grace_days!=null ? form_data.subscription_monthly_grace_days:'');
            params =params.append('bimonthly_late_fee_percentage', form_data.subscription_bimonthly_late_fee_percentage!=null ? form_data.subscription_bimonthly_late_fee_percentage:'');
            params =params.append('bimonthly_grace_days', form_data.subscription_bimonthly_grace_days!=null ? form_data.subscription_bimonthly_grace_days:'');
            params =params.append('quarterly_late_fee_percentage', form_data.subscription_quarterly_late_fee_percentage!=null ? form_data.subscription_quarterly_late_fee_percentage:'');
            params =params.append('quarterly_grace_days', form_data.subscription_quarterly_grace_days!=null ? form_data.subscription_quarterly_grace_days:'');
            params =params.append('fourmonthly_late_fee_percentage', form_data.subscription_fourmonthly_late_fee_percentage!=null ? form_data.subscription_fourmonthly_late_fee_percentage:'');
            params =params.append('fourmonthly_grace_days', form_data.subscription_fourmonthly_grace_days!=null ? form_data.subscription_fourmonthly_grace_days:'');          
            params =params.append('halfyearly_late_fee_percentage', form_data.subscription_halfyearly_late_fee_percentage!=null ? form_data.subscription_halfyearly_late_fee_percentage:'');
            params =params.append('halfyearly_grace_days', form_data.subscription_halfyearly_grace_days!=null ? form_data.subscription_halfyearly_grace_days:'');
            params =params.append('yearly_late_fee_percentage', form_data.subscription_yearly_late_fee_percentage!=null ? form_data.subscription_halfyearly_grace_days:'');
            params =params.append('yearly_grace_days', form_data.subscription_yearly_grace_days!=null ? form_data.subscription_yearly_grace_days:'');
          }
        }  
  
        params =params.append('renewal_criteria', form_data.renewal_criteria);
        params =params.append('sub_auto_renewal', form_data.sub_auto_renewal==true?'1':'0');  
      }
     
      
      
      if(form_data.billing=='post_paid'){
        params =params.append('billing', form_data.billing);
        params=params.append('time_period',form_data.time_period);
        params=params.append('per_session_amount',form_data.per_session_amount);
        params=params.append('sub_auto_renewal',form_data.sub_auto_renewal='1');
        params =params.append('link_session_count_to_attendance_flag', form_data.link_session_count_to_attendance_flag==true?'1':'0');
      }
    } 
    const local_feeplans_url=AppComponent.API_URL+'feesetup/SetFeeSetup';
    return this.http_client.post(local_feeplans_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.New_feePlans_details =data['Fee Setup State'];
            if(this.New_feePlans_details.length!=0)
            {         
              return this.New_feePlans_details;
            }
            else
              {
                return '';
              }
          }),
    )
  }

  getSpecificFeePlanDetails(organization_id,academy_fees_id)
  {
   
    let params = new HttpParams();   
    params =params.append('organization_id',organization_id);  
    params =params.append('filters[academy_fees_id]', academy_fees_id);
    
    const local_fee_url=AppComponent.API_URL+'feesetup/GetFeeSetup';
    return this.http_client.post(local_fee_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            if(data['status']==200 && data['message']=='Fee plan details.')
            {         
              return data['data']['organization_fee_plans'];
            }
            else
            {
              return '';
            }
          }),
    )
  }

  IsAllowDeleteFeePlan(organization_id,academy_fees_id)
  {
    let params = new HttpParams();   
    params =params.append('organization_id', organization_id); 
    params =params.append('academy_fees_id', academy_fees_id);    
    const local_delete_fee_flag_url=AppComponent.API_URL+'feesetup/IsAllowDeleteFeePlan';
    return this.http_client.post(local_delete_fee_flag_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.allowDelete =data['fee_plan_details'];
            if(this.allowDelete.length!=0)
            {         
              return this.allowDelete;
            }
            else
              {
                return '';
              }
          }),
    )
  }

    DeleteFeePlan(academy_fees_id,deleted_by_customer_id)
    {
      let params = new HttpParams();   
      params =params.append('academy_fees_id', academy_fees_id); 
      params =params.append('deleted_by_customer_id', deleted_by_customer_id);    
      const local_delete_fee_plan_url=AppComponent.API_URL+'feesetup/DeleteFeePlan';
      return this.http_client.post(local_delete_fee_plan_url,params,this.httpOptions)
      .pipe(
          map((data:any) =>{
            this.delete_fees_plan_details =data['delete_fees_plan_details'];
            if(this.delete_fees_plan_details.length!=0)
            {         
              return this.delete_fees_plan_details;
            }
            else
              {
                return '';
              }
          }),
    )
  }

//update Free plan
  updateFeeSetup(academy_fees_id,organization_id,fee_setup_name,fee_setup_payment_type,monthlyAmount,
  quarterlyAmount,halfYearlyAmount,yearlyAmount,oneTimePaymentAmount,chkProrata,academy_center_id,
  total_installment_amount,total_installments,installments_repeat_annually,
  installments_amount)
  {
    let params = new HttpParams();   
    params =params.append('academy_fees_id[0]', academy_fees_id); 
    params =params.append('organization_id', organization_id); 
    params =params.append('fee_setup_name', fee_setup_name);
    params =params.append('fee_setup_payment_type', fee_setup_payment_type);
    if(fee_setup_payment_type=="Recurring")
    { 
      if(monthlyAmount!=null)
      {
        params =params.append('monthlyAmount', monthlyAmount);
      }
      else
      {
        params =params.append('monthlyAmount', "0");
      }
      if(quarterlyAmount!=null)
      {
        params =params.append('quarterlyAmount', quarterlyAmount);
      }
      else
      {
        params =params.append('quarterlyAmount', "0");
      }
      if(halfYearlyAmount!=null)
      {
        params =params.append('halfYearlyAmount', halfYearlyAmount);
      }
      else
      {
        params =params.append('halfYearlyAmount', "0");
      }
      if(yearlyAmount!=null)
      {
        params =params.append('yearlyAmount', yearlyAmount);
      }
      else
      {
        params =params.append('yearlyAmount', "0");
      }
      params =params.append('chkProrata', chkProrata);
    }
    if(fee_setup_payment_type=="Onetime")
    { 
      params =params.append('oneTimePaymentAmount', oneTimePaymentAmount);
    } 
    for(var i=0;i<academy_center_id.length;i++)
    {
      params =params.append('academy_center_id['+i+']', academy_center_id[i]);
    }  
      
    if(fee_setup_payment_type=="Installments")
    {
      params =params.append('total_installment_amount', total_installment_amount);
      params =params.append('total_installments', total_installments );
      params =params.append('installments_repeat_annually', installments_repeat_annually );
      installments_amount.forEach((element,i) => {
        params =params.append('installments_amount['+i+']', element);
      });
    }
    const local_update_fee_plan_url=AppComponent.API_URL+'feesetup/SetFeeSetup';
    return this.http_client.post(local_update_fee_plan_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.update_fees_plan_details =data['Fee Setup State'];
            if(this.update_fees_plan_details.length!=0)
            {         
              return this.update_fees_plan_details;
            }
            else
              {
                return '';
              }
          }),
    )

  }

  getCenterDetails(customer_id, organization_id,is_admin)
  {
    const  params = new  HttpParams().set('customer_id', customer_id).set('organization_id', organization_id).set('is_admin', is_admin); 
    
    const local_center_url=AppComponent.API_URL+'center/GetCenterListWithGroups ';
    return this.http_client.post(local_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.center_data =data['Academy Center Ids'];
            if(this.center_data.length!=0)
            {         
              return this.center_data;
            }
            else
              {
                return '';
              }
          }),
    )    
  }

  updateFeePlanStatus(organization_id,academy_fees_id,status){
    let params=new HttpParams();
    params=params.append('organization_id',organization_id);
    params=params.append('academy_fees_id',academy_fees_id);
    params=params.append('status',status);

    const url=AppComponent.API_URL+'feesetup/UpdateFeePlanStatus';

  return this.http_client.post(url,params,this.httpOptions)
  .pipe(
    map((data:any)=>{
      if(data['status']==200){
        return data['data']['fee_plan_status'];
      }
      else{
        return '';
      }
    })
  )
  }
}