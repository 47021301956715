import { Injectable } from '@angular/core';
import {AppComponent} from '../../app.component';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ApiUrlConstant } from '../../../app/apiUrl.constant';

@Injectable()
export class CenterService {
  academy_center_id:any;
  center_data:any='';
  organizationForm_data:any='';
  state_list:any='';
  cities_list:any='';
  Center_details:any='';
  headers=new Headers();
  isallow_delete_Center_flag:any='';
  delete_center_details:any='';
  specific_center_details:any='';
  updated_center_details:any='';
  search_center_name_details:any='';
  sport_type_list:any='';
  center_coach_list:any='';
  add_batch_form_details:any='';
  add_new_batch_details:any='';
  get_batch_details:any='';
  update_batch_details:any='';
  httpOptions; 
  api_key='AIzaSyDUDJV1FTBHW4ANl8hudvnr-cQPUFF_YYI';
  private _batchAdded: Subject<void> = new Subject();
  batchAdded: Observable<void> = this._batchAdded.asObservable();

  
  constructor(private http_client: HttpClient) {  
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }
  getCenterDetails(customer_id, organization_id , is_admin)
  {
    const  params = new  HttpParams().set('customer_id', customer_id)
                                      .set('organization_id', organization_id)
                                      .set('is_admin', is_admin);
   
    const local_center_url=AppComponent.API_URL+ApiUrlConstant.GetCenterListWithGroups;
    return this.http_client.post(local_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            
            this.center_data =data['Academy Center Ids'];
            if(this.center_data.length!=0)
            {         
              return this.center_data;
            }
            else
              {
                return '';
              }
          }),
        ) 
  }
  //Countries list
  AddOrganizationFormData()
  {    
    const contries_list_url=AppComponent.API_URL+ApiUrlConstant.AddOrganizationFormData;
    return this.http_client.post(contries_list_url,[],this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.organizationForm_data =data['add_organization_form_data'];
            if(this.organizationForm_data.length!=0)
            {         
              return this.organizationForm_data;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  //State List
  GetStates(country_id)
  { 
    const  params = new  HttpParams().set('country_id', country_id);
    var body=`country_id=${country_id}`;   
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.GetStates;
    return this.http_client.post(state_list_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.state_list =data['states_list'];
            if(this.state_list.length!=0)
            {         
              return this.state_list;
            }
            else
              {
                return '';
              }
          }),
        )
  }
  GetCities(country_id,state_id)
  { 
    const  params = new  HttpParams().set('country_id', country_id).set('state_id', state_id);
      
    const local_center_url=AppComponent.API_URL+ApiUrlConstant.GetCities;
    return this.http_client.post(local_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.cities_list =data['cities_list'];
            if(this.cities_list.length!=0)
            {         
              return this.cities_list;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  AddCenter(organization_id,customer_id,center_add_form,
  file_content,thumbnail_file_content,latitude,longitude)
  {
    let params = new  HttpParams();
    params =params.append('organization_id', organization_id);
    params =params.append('customer_id', customer_id);
    params =params.append('center_name', center_add_form.center_name);    
    params =params.append('country_id', center_add_form.country_id);
    params =params.append('state_id', center_add_form.state_id);
    params =params.append('city_id', center_add_form.city);
    params =params.append('address_line1', center_add_form.address_line1);

    if(center_add_form.capacity!=null && center_add_form.capacity!='')
    {
      params =params.append('capacity', center_add_form.capacity);
    }
   

    if(center_add_form.address_line2!=null)
    {
      params =params.append('address_line2', center_add_form.address_line2);
    }    
    
    params =params.append('zipcode', center_add_form.zipcode);
    
    // if(center_add_form.gst_no!='' && center_add_form.gst_no!=null){
    //   params = params.append('gst_no',center_add_form.gst_no);
    // }

    if(center_add_form.no_of_courts!='' && center_add_form.no_of_courts!=null){
      params = params.append('no_of_courts',center_add_form.no_of_courts);
    }

    if(center_add_form.court_type!='' && center_add_form.court_type!=null){
      params = params.append('court_type',center_add_form.court_type);
    }

    if(center_add_form.point_contact_name!='' && center_add_form.point_contact_name!=null){
      params = params.append('point_contact_name',center_add_form.point_contact_name);
    }
    if(center_add_form.point_contact_number!='' && center_add_form.point_contact_number!=null){
      
      params = params.append('point_contact_number',center_add_form.point_contact_number);
      params = params.append('point_contact_number_contry_code',center_add_form.point_contact_number_contry_code);
    }
    if(center_add_form.point_contact_email_address!='' && center_add_form.point_contact_email_address!=null){
      params = params.append('point_contact_email_address',center_add_form.point_contact_email_address);
    }
    if(file_content!=null)
    { 
      params =params.append('file_content',file_content);
      params =params.append('thumbnail_file_content',thumbnail_file_content);
    }
    if(latitude!='' && latitude!=null){
      params =params.append('latitude',latitude);
      params =params.append('longitude',longitude);
    }
    
    params =params.append('sport_id', center_add_form.sport_id);
    params =params.append('sport_type',center_add_form.sport_type); 

    const local_add_center_url=AppComponent.API_URL+ApiUrlConstant.AddCenter;
    return this.http_client.post(local_add_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.Center_details =data['Center_details'];
            if(this.Center_details.length!=0)
            {         
              return this.Center_details;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  checkDeleteCenterFlag(organization_id,academy_center_id)
  {
    let params = new HttpParams();
    params =params.append('organization_id', organization_id);     
    params =params.append('academy_center_id', academy_center_id);

    const local_isallowDelete_center_url=AppComponent.API_URL+ApiUrlConstant.IsAllowDeleteCenter;
    return this.http_client.post(local_isallowDelete_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.isallow_delete_Center_flag =data['center_details'];
            if(this.isallow_delete_Center_flag.length!=0)
            {         
              return this.isallow_delete_Center_flag;
            }
            else
              {
                return '';
              }
          }),
        )
  }
  DeleteCenter(academy_center_id,deleted_by_customer_id)
  {
    let params = new HttpParams();   
    params =params.append('academy_center_id', academy_center_id);
    params =params.append('deleted_by_customer_id', deleted_by_customer_id);
    const local_Delete_center_url=AppComponent.API_URL+ApiUrlConstant.DeleteCenter;
    return this.http_client.post(local_Delete_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.delete_center_details =data['delete_center_details'];
            if(this.delete_center_details.length!=0)
            {         
              return this.delete_center_details;
            }
            else
              {
                return '';
              }
          }),
        )

  }

  GetCenterDetails(academy_center_id)
  {
   let params = new HttpParams();  
    params =params.append('academy_center_id', academy_center_id);    
    const local_Delete_center_url=AppComponent.API_URL+ApiUrlConstant.GetCenterDetails;
    return this.http_client.post(local_Delete_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.specific_center_details =data['center_details'];
            if(this.specific_center_details.length!=0)
            {         
              return this.specific_center_details;
            }
            else
              {
                return '';
              }
          }),
        ) 
  }

  UpdateCenter(organization_id,customer_id,academy_center_id,contact_id,
    file_content,thumbnail_file_content,center_edit_form,lat,lng)
  {
    let params = new HttpParams();  
    params =params.append('organization_id', organization_id);  
    params =params.append('customer_id', customer_id);  
    params =params.append('center_name', center_edit_form.center_name);  
    params =params.append('capacity', center_edit_form.capacity);  
    params =params.append('country_id', center_edit_form.country_id);  
    params =params.append('state_id', center_edit_form.state_id);  
    params =params.append('city_id', center_edit_form.city);  
    params =params.append('address_line1', center_edit_form.address_line1);  
    params =params.append('address_line2', center_edit_form.address_line2);  
    params =params.append('zipcode', center_edit_form.zipcode);  
    params =params.append('academy_center_id', academy_center_id);  
    params =params.append('contact_id', contact_id); 

    
    // if(gst_no!='' && gst_no!=null){
    //   params =params.append('gst_no', gst_no); 
    // }
    if(center_edit_form.no_of_courts!='' && center_edit_form.no_of_courts!=null){
      params =params.append('no_of_courts', center_edit_form.no_of_courts);  
    }
    if(center_edit_form.court_type!='' && center_edit_form.court_type!=null){
      params =params.append('court_type', center_edit_form.court_type);   
    }  
    if(lat!='' && lat!=null){
      params =params.append('latitude', lat);   
    }    
    if(lng!='' && lng!=null){
      params =params.append('longitude', lng);   
    } 
    if(file_content!=null)
    { 
      params =params.append('file_content',file_content);
      params =params.append('thumbnail_file_content',thumbnail_file_content);      
    }  

    if(center_edit_form.point_contact_name!='' && center_edit_form.point_contact_name!=null){
      params = params.append('point_contact_name',center_edit_form.point_contact_name);
    }else{
      params = params.append('point_contact_name','');
    }
    
    if(center_edit_form.point_contact_number!='' && center_edit_form.point_contact_number!=null){
      params = params.append('point_contact_number',center_edit_form.point_contact_number);
      params = params.append('point_contact_number_contry_code',center_edit_form.point_contact_number_contry_code);
    }else{
      params = params.append('point_contact_number','');
    }
    if(center_edit_form.point_contact_email_address!='' && center_edit_form.point_contact_email_address!=null){
      params = params.append('point_contact_email_address',center_edit_form.point_contact_email_address);
    }else{
      params = params.append('point_contact_email_address','');
    }
    params =params.append('sport_id', center_edit_form.sport_id);
    params =params.append('sport_type',center_edit_form.sport_type);  

    const local_update_center_url=AppComponent.API_URL+ApiUrlConstant.UpdateCenter;
    return this.http_client.post(local_update_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.updated_center_details =data['Center_details'];
            if(this.updated_center_details.length!=0)
            {         
              return this.updated_center_details;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  CheckCenterName(organization_id,search_center_name)
  {
    let params = new HttpParams();  
    params =params.append('organization_id', organization_id); 
    params =params.append('search_center_name', search_center_name);   
    const search_center_url=AppComponent.API_URL+ApiUrlConstant.CheckCenterName;
    return this.http_client.post(search_center_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.search_center_name_details =data['center_name_details'];
            if(this.search_center_name_details.length!=0)
            {         
              return this.search_center_name_details;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  GetSportType()
  {      
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.GetSportType;
    return this.http_client.post(state_list_url,[],this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.sport_type_list =data['sport_type_details'];
            if(this.sport_type_list.length!=0)
            {         
              return this.sport_type_list;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  GetCenterCoachList(organization_id,academy_center_id,flag )
  {   
    let params = new HttpParams();
    params =params.append('organization_id', organization_id);
    params =params.append('academy_center_id', academy_center_id);
     
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.GetCenterUserList;
    return this.http_client.post(state_list_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.center_coach_list =data['User List'];
            if(this.center_coach_list.length!=0)
            {         
              return this.center_coach_list;
            }
            else
              {
                return '';
              }
          }),
        )
  }
  GetAddBatchFormDetails(organization_id)
  {
    let params = new HttpParams();
    params =params.append('organization_id', organization_id);
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.GetAddBatchFormDetails;
    return this.http_client.post(state_list_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.add_batch_form_details =data['batch_form_details'];
            if(this.add_batch_form_details.length!=0)
            {         
              return this.add_batch_form_details;
            }
            else
              {
                return '';
              }
          }),
        )
  }	

  addBatch(academy_center_id,academy_group_name,academy_group_description,start_date,
    training_days_id,monday,tuesday,wednesday,thursday,friday,saturday,sunday,
    max_strength,monday_from_timing,monday_to_timing,tuesday_from_timing,tuesday_to_timing,
    wednesday_from_timing,wednesday_to_timing,thursday_from_timing,thursday_to_timing,friday_from_timing,
    friday_to_timing,saturday_from_timing,saturday_to_timing,sunday_from_timing,sunday_to_timing,   
    end_date,coach_id,status)
  {
    
    let params = new HttpParams();
    params =params.append('academy_center_id', academy_center_id);
    params =params.append('academy_group_name', academy_group_name);
    if(academy_group_description!=null)
    {
      params =params.append('academy_group_description', academy_group_description);
    }    
    params =params.append('start_date', start_date);
    params =params.append('training_days_id', training_days_id);
    params =params.append('monday', monday);
    params =params.append('tuesday', tuesday);
    params =params.append('wednesday', wednesday);
    params =params.append('thursday', thursday);
    params =params.append('friday', friday);
    params =params.append('saturday', saturday);
    params =params.append('sunday', sunday);
    params =params.append('max_strength', max_strength);    
    if(monday=="1")
    {
      params =params.append('monday_from_timing', monday_from_timing);
      params =params.append('monday_to_timing', monday_to_timing);
    }   
    if(tuesday=="1")
    {
      params =params.append('tuesday_from_timing', tuesday_from_timing);
      params =params.append('tuesday_to_timing', tuesday_to_timing);
    }
    if(wednesday=="1")
    {
      params =params.append('wednesday_from_timing', wednesday_from_timing);
      params =params.append('wednesday_to_timing', wednesday_to_timing);
    }
    if(thursday=="1")
    {
      params =params.append('thursday_from_timing', thursday_from_timing);
      params =params.append('thursday_to_timing', thursday_to_timing);
    }
    if(friday=="1")
    {
      params =params.append('friday_from_timing', friday_from_timing);
      params =params.append('friday_to_timing', friday_to_timing);
    }
    if(saturday=="1")
    {
      params =params.append('saturday_from_timing', saturday_from_timing);
      params =params.append('saturday_to_timing', saturday_to_timing);
    }
    if(sunday=="1")
    {
      params =params.append('sunday_from_timing', sunday_from_timing);
      params =params.append('sunday_to_timing', sunday_to_timing);
    } 
    params =params.append('end_date', end_date);
    // if(sport_id!=undefined && sport_id!='')
    // {
    //   params =params.append('sport_id', sport_id);
    // }    
    if(coach_id!=null || coach_id!=undefined)
    {
      for(var i=0;i<coach_id.length;i++)
      {
        params =params.append('coach_id['+i+']', coach_id[i]);
      }
    }
    if(status!=undefined || status!='')   
    {
      params =params.append('status', status);
    }
    // if(is_limited_sessions==true)
    // {
    //   params =params.append('is_limited_sessions', '1');
    //   params =params.append('no_of_sessions', no_of_sessions);
    // }
    // else{
    //     params =params.append('is_limited_sessions', '0');
    // }
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.SetDefaultBatch;
    return this.http_client.post(state_list_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.add_new_batch_details =data['Batch State'];
            if(this.add_new_batch_details.length!=0)
            { 
              this._batchAdded.next();        
              return this.add_new_batch_details;
              
            }
            else
              {
                return '';
              }
          }),
        )
  }	
  GetBatchDetails(academy_center_id,academy_group_id)
  {
    let params = new HttpParams();
    params =params.append('academy_center_id', academy_center_id);
    params =params.append('academy_group_id', academy_group_id);
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.GetBatchDetails;
    return this.http_client.post(state_list_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.get_batch_details =data['batch_details'];
            if(this.get_batch_details.length!=0)
            {         
              return this.get_batch_details;
            }
            else
              {
                return '';
              }
          }),
        )
  }
  
  updateBatchDetails(academy_center_id,academy_group_id,academy_group_name,academy_group_description,start_date,
    training_days_id,monday,tuesday,wednesday,thursday,friday,saturday,sunday,
    max_strength,monday_from_timing,monday_to_timing,tuesday_from_timing,tuesday_to_timing,
    wednesday_from_timing,wednesday_to_timing,thursday_from_timing,thursday_to_timing,friday_from_timing,
    friday_to_timing,saturday_from_timing,saturday_to_timing,sunday_from_timing,sunday_to_timing,   
    end_date,coach_id,status)
  {
    let params = new HttpParams();
    params =params.append('academy_center_id', academy_center_id);
    params =params.append('academy_group_id', academy_group_id);
    params =params.append('academy_group_name', academy_group_name);
    if(academy_group_description!=null)
    {
      params =params.append('academy_group_description', academy_group_description);
    }
    params =params.append('start_date', start_date);
    params =params.append('training_days_id', training_days_id);
    params =params.append('monday', monday);
    params =params.append('tuesday', tuesday);
    params =params.append('wednesday', wednesday);
    params =params.append('thursday', thursday);
    params =params.append('friday', friday);
    params =params.append('saturday', saturday);
    params =params.append('sunday', sunday);
    params =params.append('max_strength', max_strength);
    if(monday=="1")
    {
      params =params.append('monday_from_timing', monday_from_timing);
      params =params.append('monday_to_timing', monday_to_timing);
    }   
    if(tuesday=="1")
    {
      params =params.append('tuesday_from_timing', tuesday_from_timing);
      params =params.append('tuesday_to_timing', tuesday_to_timing);
    }
    if(wednesday=="1")
    {
      params =params.append('wednesday_from_timing', wednesday_from_timing);
      params =params.append('wednesday_to_timing', wednesday_to_timing);
    }
    if(thursday=="1")
    {
      params =params.append('thursday_from_timing', thursday_from_timing);
      params =params.append('thursday_to_timing', thursday_to_timing);
    }
    if(friday=="1")
    {
      params =params.append('friday_from_timing', friday_from_timing);
      params =params.append('friday_to_timing', friday_to_timing);
    }
    if(saturday=="1")
    {
      params =params.append('saturday_from_timing', saturday_from_timing);
      params =params.append('saturday_to_timing', saturday_to_timing);
    }
    if(sunday=="1")
    {
      params =params.append('sunday_from_timing', sunday_from_timing);
      params =params.append('sunday_to_timing', sunday_to_timing);
    } 
    params =params.append('end_date', end_date);
    // params =params.append('sport_id', sport_id);
    if(coach_id!=null || coach_id!=undefined)
    {
      for(var i=0;i<coach_id.length;i++)
      {
        params =params.append('coach_id['+i+']', coach_id[i]);
      }
    }
    if(status!='' || status!=undefined)
    {
      params =params.append('status', status);
    }        
    
    // if(is_limited_sessions==true)
    // {
    //   params =params.append('is_limited_sessions', '1');
    //   params =params.append('no_of_sessions', no_of_sessions);
    // }
    // else{
    //     params =params.append('is_limited_sessions', '0');
    // }
    const state_list_url=AppComponent.API_URL+ApiUrlConstant.UpdateBatchDetails;
    return this.http_client.post(state_list_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.update_batch_details =data['update_batch_details'];
            if(this.update_batch_details.length!=0)
            {         
              return this.update_batch_details;
            }
            else
              {
                return '';
              }
          }),
        )
  }

  getFeePlansList(organization_id)
  {
    const  params = new  HttpParams().set('organization_id', organization_id);
    
    const local_feeplans_url=AppComponent.API_URL+ApiUrlConstant.GetFeeSetup;
    return this.http_client.post(local_feeplans_url,params,this.httpOptions)
    .pipe(
          map((data:any) =>{
                     
            if(data['status']==200 && data['message']=='Fee plan details.')
            {         
              return data['data']['organization_fee_plans'];
            }
            else
            {
              return '';
            }
          }),
    )
  }

  centerFeesSetup(academy_center_id,academy_fees_id)
  {
    let params = new HttpParams();
    params =params.append('academy_center_id[0]', academy_center_id);
    
    var j=0;
    academy_fees_id.forEach((element,i) => {
      
      if(academy_fees_id[0]==0 && i>0){
        
        params =params.append('academy_fees_id['+j+']', element);
        j++;
      }
      if(academy_fees_id[0]!=0){
        params =params.append('academy_fees_id['+i+']', element);
      }
      
    });
    

    const local_feeplans_url=AppComponent.API_URL+ApiUrlConstant.SetFeeSetup;
    return this.http_client.post(local_feeplans_url,params,this.httpOptions)
    .pipe(
      map((data:any) =>{
        
        if(data['Fee Setup State'].length!=0)
        {         
          return data['Fee Setup State'];
        }
        else
          {
            return '';
          }
      }),
)
  }

  getCordinateByAddress(location)
  {
    return this.http_client.get('https://maps.googleapis.com/maps/api/geocode/json?address='+location+'&key='+this.api_key)
    .pipe(tap((data)=>
      ( data   ))
      
    );
  }

}